<template>
  <div class="figure__wrapper" :class="{ 'figure__wrapper-details': isDetails }">
    <svg
      :width="defaultSvgParams.width * figureSvgScale"
      :height="defaultSvgParams.height * figureSvgScale"
      :viewBox="`0 0 ${defaultSvgParams.width * figureSvgScale}
           ${defaultSvgParams.height * figureSvgScale}`"
      ref="figure"
      :style="{ transform: `matrix(${zoom},0,0,${zoom},0,0)` }"
    >
      <!-- <defs v-if="!isNav">
        <filter x="-25%" y="-30%" width="1.5" height="1.6" id="text-bg">
          <feFlood flood-color="#fff" result="bg" />
          <feMerge>
            <feMergeNode in="bg" />
            <feMergeNode in="SourceGraphic" />
            <feMorphology in="SourceAlpha" operator="dilate" radius="4" />
          </feMerge>
        </filter>
      </defs>-->
      <ruler
        :x1="0"
        :y1="changeHeightUp"
        :x2="defaultSvgParams.width"
        :y2="changeHeightUp"
        v-if="!isNav"
      />
      <ruler
        :x1="0"
        :y1="changeHeightDown"
        :x2="defaultSvgParams.width"
        :y2="changeHeightDown"
        v-if="!isNav"
      />
      <ruler
        :x1="changeWidthLeft"
        :y1="0"
        :x2="changeWidthLeft"
        :y2="defaultSvgParams.height"
        v-if="!isNav"
      />
      <ruler
        :x1="changeWidthRight"
        :y1="0"
        :x2="changeWidthRight"
        :y2="defaultSvgParams.height"
        v-if="!isNav"
      />
      <size-arrow :path="arrowWidth" :is-details="isDetails" color="violet" v-if="!isNav" />
      <size-arrow :path="arrowVerticalTop" :is-details="isDetails" color="grey" v-if="!isNav" />
      <size-arrow :path="arrowVerticalBottom" :is-details="isDetails" color="grey" v-if="!isNav" />
      <size
        v-if="!isNav"
        :x="changeWidthLeft - 30"
        :y="sideATopSizeY"
        :text="`A = ${cathetHeight}`"
        :fill="colors.grey"
      />
      <size
        v-if="!isNav"
        :x="changeWidthLeft - 30"
        :y="sideABottomSizeY"
        :text="`A = ${cathetHeight}`"
        :fill="colors.grey"
      />
      <size
        v-if="!isNav"
        :x="(defaultSvgParams.width * figureSvgScale) / 2"
        :y="changeHeightDown + 30"
        :text="`B = ${slopeParams.width}`"
        :fill="isDetails ? colors.grey : colors.violet"
      />
      <defs v-if="isPlate">
        <clipPath :id="`figureTopMask--sector-${slopeFromState.uid}`">
          <path
            :d="triangleTopMask"
            fill="none"
            :stroke="figureStrokeColor"
            :stroke-width="strokeWidth"
            style="stroke-linecap: round"
          ></path>
          // triangle mask top
        </clipPath>
        <clipPath :id="`figureBottomMask--sector-${slopeFromState.uid}`">
          <path
            :d="triangleBottomMask"
            fill="none"
            :stroke="figureStrokeColor"
            :stroke-width="strokeWidth"
            style="stroke-linecap: round"
          ></path>
          // triangle mask bottom
        </clipPath>
      </defs>
      <g v-if="isDetails" name="rowsForTooltip">
        <g :clip-path="`url(#figureTopMask--sector-${slopeFromState.uid})`">
          <!--clip-path="url(#figureTopMask)"-->
          <g
            :transform="`rotate(-${platesRotationDegree}
              ${changeWidthRight} ${(defaultSvgParams.height * figureSvgScale) / 2})`"
          >
            <g v-for="i in rowsCount" :key="i" class="row__overlay">
              <rect
                :x="changeWidthRight - plateWidth * i"
                :y="
                  i % 2 === 0
                    ? (defaultSvgParams.height * figureSvgScale) / 2 - rowHeight - plateHeight / 2
                    : (defaultSvgParams.height * figureSvgScale) / 2 - rowHeight
                "
                :width="plateWidth - 1"
                :height="i % 2 === 0 ? rowHeight + plateHeight : rowHeight"
                fill="#F6F6F6"
                stroke="#999999"
                stroke-width="1"
                @mouseover="showRowInfo(i)"
                @mouseout="hideRowInfo()"
              ></rect>
              <line
                :x1="changeWidthRight - plateWidth * i"
                :y1="
                  i % 2 === 0
                    ? (defaultSvgParams.height * figureSvgScale) / 2 -
                      rowHeight +
                      plateHeight * n -
                      plateHeight / 2
                    : (defaultSvgParams.height * figureSvgScale) / 2 - rowHeight + plateHeight * n
                "
                :x2="changeWidthRight - plateWidth * i + plateWidth - 1"
                :y2="
                  i % 2 === 0
                    ? (defaultSvgParams.height * figureSvgScale) / 2 -
                      rowHeight +
                      plateHeight * n -
                      plateHeight / 2
                    : (defaultSvgParams.height * figureSvgScale) / 2 - rowHeight + plateHeight * n
                "
                stroke="#999999"
                stroke-width="1"
                v-for="n in i % 2 === 0 ? itemPerRow : itemPerRow - 1"
                :key="n"
              ></line>
              <!--(plateHeight * n + (plateHeight / 2)) + shift-->
            </g>
          </g>
          <g
            :transform="`rotate(-${platesRotationDegree}
              ${changeWidthLeft} ${(defaultSvgParams.height * figureSvgScale) / 2})`"
          >
            <!--
          :transform="`rotate(-${platesRotationDegree}
              ${changeWidthLeft} ${(defaultSvgParams.height * figureSvgScale) / 2})`"
          -->
            <row-number
              :opts="getRowNumberOpts(i)"
              v-for="i in rowsCount"
              :key="`top-row-number--${i}`"
            />
          </g>
        </g>
        <g :clip-path="`url(#figureBottomMask--sector-${slopeFromState.uid})`">
          <!--clip-path="url(#figureBottomMask)"-->
          <g
            :transform="`rotate(${platesRotationDegree}
              ${changeWidthRight} ${(defaultSvgParams.height * figureSvgScale) / 2})`"
          >
            <g v-for="i in rowsCount" :key="i" class="row__overlay">
              <rect
                :x="changeWidthRight - plateWidth * i"
                :y="
                  i % 2 === 0
                    ? (defaultSvgParams.height * figureSvgScale) / 2 - plateHeight / 2
                    : (defaultSvgParams.height * figureSvgScale) / 2
                "
                :width="plateWidth - 1"
                :height="i % 2 === 0 ? rowHeight + plateHeight : rowHeight"
                fill="#F6F6F6"
                stroke="#999999"
                stroke-width="1"
                @mouseover="showRowInfo(i)"
                @mouseout="hideRowInfo()"
              ></rect>
              <line
                :x1="changeWidthRight - plateWidth * i"
                :y1="
                  i % 2 === 0
                    ? (defaultSvgParams.height * figureSvgScale) / 2 +
                      plateHeight * n -
                      plateHeight / 2
                    : (defaultSvgParams.height * figureSvgScale) / 2 + plateHeight * n
                "
                :x2="changeWidthRight - plateWidth * i + plateWidth - 1"
                :y2="
                  i % 2 === 0
                    ? (defaultSvgParams.height * figureSvgScale) / 2 +
                      plateHeight * n -
                      plateHeight / 2
                    : (defaultSvgParams.height * figureSvgScale) / 2 + plateHeight * n
                "
                stroke="#999999"
                stroke-width="1"
                v-for="n in i % 2 === 0 ? itemPerRow : itemPerRow - 1"
                :key="n"
              ></line>
              <!--(plateHeight * n + (plateHeight / 2)) + shift-->
            </g>
          </g>
          <g
            :transform="`rotate(${platesRotationDegree}
              ${changeWidthLeft} ${(defaultSvgParams.height * figureSvgScale) / 2})`"
          >
            <!--
          :transform="`rotate(${platesRotationDegree}
              ${changeWidthLeft} ${(defaultSvgParams.height * figureSvgScale) / 2})`"
          -->
            <row-number
              :opts="getRowNumberOpts(i)"
              v-for="i in rowsCount"
              :key="`bottom-row-number--${i}`"
            />
          </g>
        </g>
      </g>
      <size-arrow
        :path="arrowDirection"
        :is-details="isDetails"
        :color="arrowStrokeColor"
        :stroke-width="arrowDirectionStrokeWidth"
        :stroke-dasharray="isDetails ? 20 : 0"
        :opacity="isDetails ? 0.7 : 1"
        :transform="`rotate(${arrowDirectionTopRotationDegree}
            ${arrowLength - arrowBias} ${arrowDirectionY})`"
      />
      <size-arrow
        :path="arrowDirection"
        :is-details="isDetails"
        :color="arrowStrokeColor"
        :stroke-width="arrowDirectionStrokeWidth"
        :stroke-dasharray="isDetails ? 20 : 0"
        :opacity="isDetails ? 0.7 : 1"
        :transform="`rotate(${arrowDirectionBottomRotationDegree}
            ${arrowLength - arrowBias} ${arrowDirectionY})`"
      />
      <path
        :d="doubleSideTriangleHorizontal"
        class="scheme-circuit"
        fill="none"
        :stroke="figureStrokeColor"
        :stroke-width="strokeWidth"
        style="stroke-linecap: round"
      ></path>
    </svg>
  </div>
</template>

<script>
import RowNumber from '@/components/figure_elements/RowNumber'
import {
  getRowNumberXForTriangles,
  getRowNumberFontSize,
  colors,
  drawSizeArrowVertical,
  getCathetSize,
  drawSizeArrowHorizontal,
  drawArrowDirection
} from '@/utils/drawing'
import Ruler from '@/components/figure_elements/Ruler'
import SizeArrow from '@/components/figure_elements/SizeArrow'
import Size from '@/components/figure_elements/Size'
import { getMin } from '@/utils'

export default {
  props: {
    isNav: Boolean,
    defaultSvgParams: Object,
    isDetails: Boolean,
    isOffsetValleyNeed: {
      type: Boolean
    },
    isPlate: Boolean,
    navId: Number,
    zoom: Number,
    rowId: {
      type: Number,
      default: 0
    },
    isForTotalPdf: {
      type: Boolean
    },
    sector: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Size,
    SizeArrow,
    Ruler,
    RowNumber
  },
  methods: {
    getRowNumberOpts(i) {
      const { changeWidthLeft, plateWidth, scale } = this
      return {
        fontSize: getRowNumberFontSize(this.plateWidth),
        x: getRowNumberXForTriangles(
          changeWidthLeft,
          plateWidth,
          i,
          this.slopeFromState.slopeParams[0].width,
          scale,
          this.slopeParams.ratio
        ),
        y: (this.defaultSvgParams.height * this.figureSvgScale) / 2,
        text: i
      }
    },
    showRowInfo(i) {
      if (this.rowId !== i) {
        this.$emit('updateRowId', i)
      }
    },
    hideRowInfo() {
      if (this.rowId !== 0) {
        this.$emit('updateRowId', 0)
      }
    }
  },
  computed: {
    colors: () => colors,
    slopeFromState() {
      if (this.isForTotalPdf) {
        return this.sector
      } else {
        return (!this.isNav && !this.isDetails) || (this.isDetails && this.isPlate)
          ? this.$store.getters.getSlope(this.slopeId)
          : this.$store.getters.getSlope(this.navId)
      }
    },
    arrowVerticalTop() {
      const { changeHeightUp, changeWidthLeft, defaultSvgParams, figureSvgScale } = this
      const x = changeWidthLeft - 30
      const y2 = (defaultSvgParams.height * figureSvgScale) / 2
      return drawSizeArrowVertical(x, changeHeightUp, y2)
    },
    arrowVerticalBottom() {
      const { changeHeightDown, changeWidthLeft, defaultSvgParams, figureSvgScale } = this
      const x = changeWidthLeft - 30
      const y1 = (defaultSvgParams.height * figureSvgScale) / 2
      return drawSizeArrowVertical(x, y1, changeHeightDown)
    },
    sideATopSizeY() {
      const { changeHeightUp, defaultSvgParams, figureSvgScale } = this
      const center = (defaultSvgParams.height * figureSvgScale) / 2
      return changeHeightUp + (center - changeHeightUp) / 2
    },
    sideABottomSizeY() {
      const { changeHeightDown, defaultSvgParams, figureSvgScale } = this
      const center = (defaultSvgParams.height * figureSvgScale) / 2
      return center + (changeHeightDown - center) / 2
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    allSlopes() {
      return this.$store.getters.allSlopes
    },
    slopeParams() {
      return this.slopeFromState.slopeParams[0]
    },
    halfPlate() {
      return this.columnsCount % 2 === 0
        ? 0
        : (this.slopeFromState.materialSize.height / 1000 / 2) * this.scale
    },
    rowsCount() {
      return Math.ceil(
        ((this.slopeFromState.slopeParams[0].width / this.slopeFromState.slopeParams[0].ratio) *
          1000) /
          this.slopeFromState.materialSize.width
      )
    },
    itemPerRow() {
      return Math.ceil(
        (Math.sqrt(
          this.slopeFromState.slopeParams[0].width ** 2 +
            (this.slopeFromState.slopeParams[0].width / this.slopeFromState.slopeParams[0].ratio) **
              2
        ) *
          this.scale) /
          ((this.slopeFromState.materialSize.height / 1000) * this.scale)
      )
    },
    plateHeight() {
      return (this.slopeFromState.materialSize.height / 1000) * this.scale
    },
    plateWidth() {
      return (this.slopeFromState.materialSize.width / 1000) * this.scale
    },
    rowHeight() {
      return this.itemPerRow * this.plateHeight
    },
    platesRotationDegree() {
      // высчитываем угол поворота
      return 90 - (Math.atan(1 / this.slopeParams.ratio) * 180) / Math.PI
    },
    arrowStrokeColor() {
      return this.isNav ? 'nav' : 'grey'
    },
    figureStrokeColor() {
      return this.isNav || this.isDetails ? 'var(--grey-stroke)' : 'var(--black)'
    },
    strokeWidth() {
      return this.isNav || this.isDetails ? 2 : 3
    },
    arrowDirectionStrokeWidth() {
      return this.isNav || this.isDetails ? 2 : 4
    },

    figureSvgScale() {
      return this.isNav ? this.defaultSvgParams.navScale : 1
    },
    arrowDirectionSize() {
      return this.isNav ? 5 : 10
    },
    arrowWidth() {
      const { changeHeightDown, changeWidthLeft, changeWidthRight } = this
      const y = changeHeightDown + 30
      return drawSizeArrowHorizontal(changeWidthLeft, y, changeWidthRight)
    },
    arrowDirection() {
      const { arrowDirectionStartX, arrowDirectionEndX, arrowDirectionY, arrowDirectionSize } = this
      return drawArrowDirection(
        arrowDirectionStartX,
        arrowDirectionEndX,
        arrowDirectionY,
        arrowDirectionSize
      )
    },
    doubleSideTriangleHorizontal() {
      const {
        changeWidthRight,
        defaultSvgParams,
        figureSvgScale,
        changeWidthLeft,
        changeHeightDown,
        changeHeightUp
      } = this
      return `M${changeWidthRight},${(defaultSvgParams.height * figureSvgScale) / 2}
      L${changeWidthLeft},${changeHeightDown}
      M${changeWidthLeft},${changeHeightDown}
      L${changeWidthLeft},${changeHeightUp}
      M${changeWidthLeft},${changeHeightUp}
      L${changeWidthRight},${(defaultSvgParams.height * figureSvgScale) / 2}
      M${changeWidthRight},${(defaultSvgParams.height * figureSvgScale) / 2}
      L${changeWidthLeft},${(defaultSvgParams.height * figureSvgScale) / 2}
      M${changeWidthLeft},${(defaultSvgParams.height * figureSvgScale) / 2}`
    },
    triangleTopMask() {
      const {
        changeWidthRight,
        defaultSvgParams,
        figureSvgScale,
        changeWidthLeft,
        changeHeightUp
      } = this
      return `M${changeWidthRight},${(defaultSvgParams.height * figureSvgScale) / 2}
      L${changeWidthLeft},${changeHeightUp}
      L${changeWidthLeft},${(defaultSvgParams.height * figureSvgScale) / 2} Z`
    },
    triangleBottomMask() {
      const {
        changeWidthRight,
        defaultSvgParams,
        figureSvgScale,
        changeWidthLeft,
        changeHeightDown
      } = this
      return `M${changeWidthRight},${(defaultSvgParams.height * figureSvgScale) / 2}
      L${changeWidthLeft},${changeHeightDown}
      L${changeWidthLeft},${(defaultSvgParams.height * figureSvgScale) / 2} Z`
    },
    scaleH() {
      // коэффициент высоты
      return (
        (this.defaultSvgParams.baseHeight * this.figureSvgScale * 100) /
        (this.slopeParams.width / (this.slopeParams.ratio / 2))
      )
    },
    scaleW() {
      // коэффициент ширины
      return (this.defaultSvgParams.baseWidth * this.figureSvgScale * 100) / this.slopeParams.width
    },
    scale() {
      // применяемый коэффициент в зависимости от того какой из коэффициентов больше
      return getMin(this.scaleW, this.scaleH)
    },
    changeWidthLeft() {
      // изменяем ширину влево
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 -
        (this.slopeParams.width * this.scale) / 2
      )
    },
    changeWidthRight() {
      // изменяем ширину вправо
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 +
        (this.slopeParams.width * this.scale) / 2
      )
    },
    changeHeightUp() {
      // изменяем высоту вверх
      return (
        (this.defaultSvgParams.height * this.figureSvgScale) / 2 -
        (this.slopeParams.width / this.slopeParams.ratio) * this.scale
      )
    },
    changeHeightDown() {
      // изменяем высоту вниз
      return (
        (this.defaultSvgParams.height * this.figureSvgScale) / 2 +
        (this.slopeParams.width / this.slopeParams.ratio) * this.scale
      )
    },
    arrowDirectionStartX() {
      // изменяем ширину направляющей стрелки влево
      return this.arrowLength - this.arrowBias + this.arrowNewLength * 0.2
    },
    arrowDirectionEndX() {
      // изменяем ширину напрвляющей стрелки вправо
      return this.arrowLength - this.arrowBias + this.arrowNewLength - this.arrowNewLength * 0.2
    },
    arrowDirectionY() {
      // изменяем положение напрвляющей стрелки по высоте
      return (this.defaultSvgParams.height * this.figureSvgScale) / 2
    },
    arrowLength() {
      return (
        this.changeWidthLeft + (this.slopeParams.width * this.scale) / (this.slopeParams.ratio + 1)
      )
    },
    arrowNewLength() {
      return Math.sqrt(this.arrowLength ** 2 + this.arrowBias ** 2) - this.changeWidthLeft
    },
    arrowBias() {
      return (
        (this.slopeParams.width * this.scale) /
        (this.slopeParams.ratio + 1) /
        this.slopeParams.ratio
      )
    },
    arrowDirectionBottomStartX() {
      // изменяем ширину направляющей стрелки влево
      return (
        this.changeWidthLeft +
        (this.slopeParams.width / this.slopeParams.ratio) *
          this.scale *
          (this.slopeParams.ratio / 20)
      )
    },
    arrowDirectionBottomEndX() {
      // изменяем ширину напрвляющей стрелки вправо
      return (
        (this.changeWidthLeft + (this.slopeParams.width / this.slopeParams.ratio) * this.scale) *
        0.8
      )
    },
    arrowDirectionBottomY() {
      // изменяем положение напрвляющей стрелки по высоте
      return (
        (this.defaultSvgParams.height * this.figureSvgScale) / 2 +
        (this.slopeParams.width / this.slopeParams.ratio) *
          this.scale *
          (this.slopeParams.ratio / 20)
      )
    },
    arrowDirectionTopRotationDegree() {
      // высчитываем угол поворота направляющей
      // стрелки что бы она была перпендикулярна гипотенузе
      return (Math.atan(1 / this.slopeParams.ratio) * 180) / Math.PI - 90
    },
    arrowDirectionBottomRotationDegree() {
      // высчитываем угол поворота направляющей
      // стрелки что бы она была перпендикулярна гипотенузе
      return 90 - (Math.atan(1 / this.slopeParams.ratio) * 180) / Math.PI
    },
    cathetHeight() {
      return getCathetSize(this.slopeParams.width, this.slopeParams.ratio)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass"></style>
